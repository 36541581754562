export const FETCH_PERFORMANCE_SCORE = "FETCH_PERFORMANCE_SCORE";
export const CANCEL_PERFORMANCE_SCORE = "CANCEL_PERFORMANCE_SCORE";
export const FETCH_PERFORMANCE_SCORE_NEW = "FETCH_PERFORMANCE_SCORE_NEW";
export const CANCEL_VARIANT_PERFORMANCE_SCORE =
  "CANCEL_VARIANT_PERFORMANCE_SCORE";

export interface FetchPerformanceScore {
  type: typeof FETCH_PERFORMANCE_SCORE;
  payload: FetchPerformanceScorePayload;
}

export interface FetchPerformanceScoreNew {
  type: typeof FETCH_PERFORMANCE_SCORE_NEW;
  payload: FetchPerformanceScoreNewPayload;
}

export interface FetchPerformanceScorePayload {
  callback: ({
    error,
    performanceScore,
  }: {
    error: boolean;
    performanceScore?: PerformanceScoreType;
    cancel?: boolean;
  }) => void;
  body: {
    headline?: string;
    description?: string;
    primary_text?: string;
    brand_name: string;
  };
}
export interface FetchPerformanceScoreNewPayload {
  callback: ({
    error,
    performanceScore,
  }: {
    error: boolean;
    performanceScore?: NewPerformanceScoreType;
    cancel?: boolean;
  }) => void;
  body: {
    account_id: string;
    ad_type: string; 
    ad_id: string;
    assets: Asset[];
    bu_name: string;
    bu_detail: string;
    industry: string;
    is_bundle: boolean;
  };
}

interface Asset {
  asset_type: string;
  asset_text: string;
}

export interface PerformanceScoreType {
  performance_labels: string;
  readability_score: number;
  brand_centric: boolean;
  customer_centric: boolean;
  benefit_label: boolean;
  offer_label: boolean;
  insights?: boolean;
  performance_score: number;
  performance_probabilities: number;
  error?: any;
  insight_generation?: boolean;
  data?: PerformanceScoreData;
  tags?: Tags;
}

interface PerformanceScoreData {
  performance_score: number;
    performance_probabilities: number;
    performance_labels: string;
    tags: Tags;
}


interface NewPerformanceScoreType {
  performance_score: number;
  performance_probabilities: number;
  performance_labels: string;
  data: {
    tags: Tags;
  }
  
}

interface Tags {
  [key: string]: {
      classification: boolean;
      intensity_score: number;
      feature_rank: number;
      intensity_significant: boolean;
  };
}


export interface RecommendationDataType extends PerformanceScoreType {
  text: string;
  isLoading?: boolean;
  isFailed?: boolean;
  isEdited?: boolean;
}
/* 
kept for future use 
// export const STORE_VARIANT_PERFORMANCE_SCORE =
//   "STORE_VARIANT_PERFORMANCE_SCORE";
// export const STORE_RECOMMENDATION_PERFORMANCE_SCORE =
//   "STORE_RECOMMENDATION_PERFORMANCE_SCORE";

// export interface StoreVariantPerformanceScore {
//   type: typeof STORE_VARIANT_PERFORMANCE_SCORE;
//   payload: StoreVariantPerformanceScorePayload;
// }

// export interface StoreVariantPerformanceScorePayload {}

// export interface StoreRecommendationPerformanceScore {
//   type: typeof STORE_RECOMMENDATION_PERFORMANCE_SCORE;
//   payload: StoreRecommendationPerformanceScorePayload;
// }

// export interface StoreRecommendationPerformanceScorePayload {}

// // initial state

// export interface EditorInitialState {}

*/
