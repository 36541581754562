import { OptionType } from "components/CreativeAI/Facebook/CreateVariants/Editor/Editor.types";
import { DynamicFilterItemType } from "components/CreativeAI_Revamp/shared/DynamicFilterBox/types";
import { sub } from "date-fns";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdSetsFBLog,
  getAdsFBLog,
  getFbActivityLogs,
  setAdSetsFBLog,
  setAdsFBLog,
  setLogAdAccountsSelection,
  setLogAdSetSelection,
  setLogAdsSelection,
} from "store/actions/CreativeAI_Revamp/LogScreen/logscreen.actions";
import {
  AdsItemType,
  FacebookInitialState,
} from "store/types/CreativeAI_Revamp/facebook.types";
import { LooseObject } from "types/GlobalTypes";
import { useCaiFbMixPanel } from "../../HomeScreen/hooks/useCaiFbMixPanel";
import { FBHomeScreenInitialStateType } from "store/types/CreativeAI_Revamp/HomeScreen/homeScreen.types";
import { OUTCOME_SALES_AD_LIMIT } from "../../utils/const";

export const useLogScreen = (selectedTabValue: "log" | "home") => {
  // local states
  const [filterState, setFilterState] = useState<DynamicFilterItemType[]>([]);
  const [isLogDownloading, setIsLogDownloading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [dateSelection, setDateSelection] = useState<{
    startDate?: Date;
    endDate?: Date;
    key?: string;
    count: number;
  }>({
    startDate: sub(new Date(), { days: 6 }),
    endDate: new Date(),
    key: "selection",
    count: 0,
  });
  // const adAccountsSelectionNew = useSelector(
  //   (state: { fbHomeScreenReducer: FBHomeScreenInitialStateType }) =>
  //     state.fbHomeScreenReducer.adAccountsSelection
  // );
  const { adAccountList, adSetList } = useSelector(
    (state: { facebookReducer: FacebookInitialState }) => state.facebookReducer
  );
  const {
    adSetSelection,
    adAccountsSelection,
    adsListLog,
    adsSelection,
    adSetListLog,
  } = useSelector(
    (state: { fbActivityLogReducer: any }) => state.fbActivityLogReducer
  );

  const dispatch = useDispatch();
  const { mixPanelLogsExported } = useCaiFbMixPanel();
  //ad accounts

  useEffect(() => {
    if (adAccountList.data.length > 0) {
      setAdAccountSelection(adAccountList.data[0]);
    } else {
      dispatch(
        setLogAdAccountsSelection({
          selection: undefined,
          selectedAdAccountId: "",
          selectedElementId: "",
        })
      );
    }

    // need to think about dependency, I don't want to reset selection after fetching more adaccount
  }, [adAccountList.data]);

  const setAdAccountSelection = (value: LooseObject) => {
    dispatch(
      setLogAdAccountsSelection({
        selection: value,
        selectedAdAccountId: value.adAccountId,
        selectedElementId: value.id,
      })
    );
  };

  //adsets

  useEffect(() => {
    if (adSetListLog.data.length > 0) {
      setAdsetSelection(adSetListLog.data);
    } else {
      setAdsetSelection([]);
    }

    // need to think about dependency, I don't want to reset selection after fetching more adsets
  }, [adSetListLog.data]);

  useEffect(() => {
    if (adAccountsSelection?.selectedAdAccountId) {
      // if (
      //   adAccountsSelectionNew?.selectedAdAccountId ===
      //   adAccountsSelection?.selectedAdAccountId
      // ) {
      //   dispatch(
      //     setAdSetsFBLog({
      //       ...adSetList,
      //     })
      //   );
      // } else {
      dispatch(
        getAdSetsFBLog({
          elementId: adAccountsSelection?.selectedElementId,
          params: {
            responseFields: "id,name",
            limit: 600,
          },
        })
      );
      // }
    } else {
      dispatch(
        setAdSetsFBLog({
          isFailed: false,
          isLoading: true,
          data: [],
        })
      );
    }
  }, [adAccountsSelection?.selectedAdAccountId]);

  const setAdsetSelection = (items: any) => {
    dispatch(
      setLogAdSetSelection({
        selectedAdSets: items,
      })
    );
  };

  // ads

  useEffect(() => {
    if (
      adSetSelection?.selectedAdSets.length > 0 &&
      adAccountsSelection?.selectedAdAccountId
    ) {
      dispatch(
        getAdsFBLog({
          params: {
            adFormat: "MOBILE_FEED_STANDARD",
            adsetIds: adSetSelection?.selectedAdSets.map((item: any) => item.adsetId),
            adAccountId: adAccountsSelection?.selectedAdAccountId,
            adsCount: OUTCOME_SALES_AD_LIMIT
          },
        })
      );
    } else {
      dispatch(
        setAdsFBLog({
          isFailed: false,
          isLoading: true,
          data: [],
        })
      );
    }
  }, [adSetSelection?.selectedAdSets]);

  useEffect(() => {
    if (adsListLog.data.length > 0) {
      setAdSelection(adsListLog.data);
    } else {
      setAdSelection([]);
    }
  }, [adsListLog.data]);

  const setAdSelection = (items: AdsItemType[]) => {
    dispatch(
      setLogAdsSelection({
        selectedAds: items,
      })
    );
  };

  // fetch logs

  useEffect(() => {
    if (selectedTabValue === "log") {
      fetchActivityLog();
    }
  }, [adsSelection?.selectedAds, dateSelection, filterState, selectedTabValue]);

  const fetchActivityLog = () => {
    if (
      adAccountsSelection?.selectedAdAccountId &&
      adSetSelection?.selectedAdSets.length > 0 &&
      dateSelection?.endDate &&
      dateSelection.startDate
    ) {
      let params = {
        to: moment(dateSelection.endDate).format("YYYY-MM-DDT23:59:59[Z]"),
        from: moment(dateSelection.startDate).format("YYYY-MM-DDT00:00:00[Z]"),
        adAccountIds: adAccountsSelection?.selectedAdAccountId,
        adsetIds: adSetSelection?.selectedAdSets
          .map((item: any) => item.adsetId)
          .join(","),
        referenceAdIds: adsSelection?.selectedAds
          .map((item: any) => item.id)
          .join(","),
        limit: 100,
      };
      const allSelected = filterState.filter((item) => item.selected);
      if (allSelected.length) {
        allSelected.forEach(
          (item) =>
            (params = {
              ...params,
              [item.adKey]: item.selected
                .map((selection: OptionType) => selection.value)
                .join(","),
            })
        );
      }
      dispatch(
        getFbActivityLogs({
          params,
        })
      );
    }
  };

  const handleDownload = () => {
    setIsLogDownloading(true);
    if (dateSelection?.endDate && dateSelection.startDate) {
      console.log(dateSelection?.endDate, dateSelection.startDate);
      let params = {
        to: moment(dateSelection.endDate).format("YYYY-MM-DDT23:59:59[Z]"),
        from: moment(dateSelection.startDate).format("YYYY-MM-DDT00:00:00[Z]"),
        adAccountIds: adAccountsSelection?.selectedAdAccountId,
        adsetIds: adSetSelection?.selectedAdSets
          .map((item: any) => item.adsetId)
          .join(","),
        referenceAdIds: adsSelection?.selectedAds
          .map((item: any) => item.id)
          .join(","),
        type: "download",
      };
      const allSelected = filterState.filter((item) => item.selected);
      if (allSelected.length) {
        allSelected.forEach(
          (item) =>
            (params = {
              ...params,
              [item.adKey]: item.selected
                .map((selection: OptionType) => selection.value)
                .join(","),
            })
        );
      }
      dispatch(
        getFbActivityLogs({
          params,
          callback: (url: any) => {
            console.log("hey ram", url);
            if (url) {
              const a = document.createElement("a");
              a.href = url;
              a.download = url.split("/").pop();
              document.body.appendChild(a);
              a.target = "_blank";
              a.click();
              document.body.removeChild(a);
            } else {
              setShowToast(true);
            }

            setIsLogDownloading(false);
          },
        })
      );
      mixPanelLogsExported(
        `${moment(dateSelection.startDate).format("DD/MM/YYYY")}-${moment(
          dateSelection.endDate
        ).format("DD/MM/YYYY")}`
      );
    }
  };

  const handleSnackBarClose = () => {
    setShowToast(false);
  };

  return {
    setAdSelection,
    setAdsetSelection,
    setAdAccountSelection,
    dateSelection,
    setDateSelection,
    setFilterState,
    handleDownload,
    isLogDownloading,
    fetchActivityLog,
    showToast,
    handleSnackBarClose,
  };
};
