import { Box, CircularProgress, IconButton } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  InitialMediaStudioType,
  RESTORE_INITIAL_MEDIA,
} from "store/types/CreativeAI/Facebook/CreateVariant/mediaStudio.types";
import { ReactComponent as Delete } from "../../../../../../assets/svg/bin.svg";
import { ReactComponent as Copy } from "../../../../../../assets/svg/copy.svg";
import { ReactComponent as LeftArrow } from "../../../../../../assets/svg/left_arrow.svg";
import { ReactComponent as RightArrow } from "../../../../../../assets/svg/right_arrow.svg";
import {
  addVariant,
  copyVariant,
  deleteVariantAPICall,
  setConfigurationState,
  updateExtraField,
  updateVariant,
  updateRecommendationMetadata,
  isShowSaveVariantPromptAction,
  updateRecommendationSelectionState,
} from "../../../../../../store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import { initialState } from "../../../../../../store/types/auth";
import {
  COPY_VARIANT,
  CreateVariantsState,
  Image_Recommendation,
  SET_ADD_VARIANT_RESPONSE,
} from "../../../../../../store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { Button, ConfirmDialog } from "../../../../../shared";
import {
  checkAdType,
  checkAdTypeForPublish,
} from "../../../utils/common.utils";

import { HashType } from "../Editor.types";
import {
  StyledMiddleElement,
  StyledPaginatedText,
  StyledRightElement,
  StyledRecommendationFooterDiv,
} from "./styled";
import { FBHomeScreenInitialStateType } from "store/types/CreativeAI_Revamp/HomeScreen/homeScreen.types";
import {
  cancelPerformanceScore,
  fetchPerformanceScore,
} from "store/actions/CreativeAI_Revamp/HomeScreen/Editor/editor.actions";
import { AdAccountState } from "store/types/adaccount";
import { PerformanceScoreType } from "store/types/CreativeAI_Revamp/HomeScreen/Editor/editor.types";
import { performanceScoreNullState } from "components/CreativeAI_Revamp/shared/PerformanceTooltip/performance.constant";
import { copyVariantNameLogic } from "components/CreativeAI_Revamp/Facebook/utils/common.utils";
import { useCaiFbMixPanel } from "components/CreativeAI_Revamp/Facebook/HomeScreen/hooks/useCaiFbMixPanel";

interface RecommendationFooterProps {
  setIsConfiguring: any;
  textFields: any;
}

export default function RecommendationFooter({
  setIsConfiguring,
  textFields,
}: RecommendationFooterProps) {
  // local states
  const [isFetchingPFScore, setIsFetchingPFScore] = useState(false);

  // redux state
  const {
    variantList,
    extraFields,
    recommendationSelectionState,
    selectedAd,
    recommendationMetaData: recommendationMetaDataRedux,
    isUpdatingVariant,
    isDeletingVariant,
    configureMetaData,
    isGeneratingPreview,
    createSpec,
  } = useSelector(
    (state: { createVariantReducer: CreateVariantsState | any }) =>
      state.createVariantReducer
  );
  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const [showDeletePopUp, setShowDeletePopUp] = useState(false);
  const dispatch = useDispatch();
  const { mixPanelUpdateVariant } = useCaiFbMixPanel();
  const { adSetSelection, adAccountsSelection } = useSelector(
    (state: { fbHomeScreenReducer: FBHomeScreenInitialStateType }) =>
      state.fbHomeScreenReducer
  );

  const { imageContext, videoContext } = useSelector(
    (state: { mediaStudioReducer: InitialMediaStudioType }) =>
      state.mediaStudioReducer
  );

  const { selectedBrand } = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );

  const formateRecommendationDate = (key: HashType) => {
    let data = recommendationMetaDataRedux?.[key]?.data;
    const newData = [];
    if (data && data.length) {
      const item = data.find(
        (item: any) => item?.text === recommendationSelectionState[key]
      );

      if (item?.isLoading) {
        newData.push({
          text: item?.text,
          isFailed: true,
          ...performanceScoreNullState,
        });
      } else {
        newData.push(item);
      }

      data.forEach((item: any) => {
        if (item?.text !== recommendationSelectionState[key]) {
          if (item?.isLoading) {
            newData.push({
              text: item?.text,
              isFailed: true,
              ...performanceScoreNullState,
            });
          } else {
            newData.push(item);
          }
        }
      });
      return {
        data: newData,
        selected: newData[0],
        noOfElementToShow: newData.length > 4 ? 4 : newData.length,
        showMore: newData.length > 4,
      };
    }
    return {};
  };
  const formateRecommendationImgDate = (key: HashType) => {
    let data = recommendationMetaDataRedux[key]?.data;
    if (data && data.length) {
      if (recommendationSelectionState[key]) {
        const temp = data.filter(
          (item: Image_Recommendation) =>
            item?.id === recommendationSelectionState[key]
        );
        data = data.filter(
          (item: Image_Recommendation) =>
            item?.id !== recommendationSelectionState[key]
        );
        data.splice(0, 0, temp[0]);
      }

      return {
        data,
        selected: data[0]?.id,
        noOfElementToShow: data.length > 4 ? 4 : data.length,
        showMore: data.length > 4,
      };
    }
    return {
      data: undefined,
    };
  };

  const formateRecommendationVideoDate = (key: HashType) => {
    let data = recommendationMetaDataRedux[key]?.data;
    if (data && data.length) {
      // if (recommendationSelectionState[key]) {
      //   const selectedIndexVal = data.splice(
      //     recommendationSelectionState[key],
      //     1
      //   );
      //   data.splice(0, 0, selectedIndexVal[0]);
      // }
      return {
        data,
        selected: data[0],
        noOfElementToShow: data.length > 4 ? 4 : data.length,
        showMore: data.length > 4,
      };
    }
    return {
      data: undefined,
    };
  };
  let recommendationMetaData: any = {
    headline: formateRecommendationDate("headline"),
    primary_text: formateRecommendationDate("primary_text"),
    description: formateRecommendationDate("description"),
  };

  if (selectedAd?.isMediaStudioAvailable) {
    recommendationMetaData[
      checkAdType(createSpec) === "link_data" ? "image" : "video"
    ] =
      checkAdType(createSpec) === "link_data"
        ? formateRecommendationImgDate("image")
        : formateRecommendationVideoDate("video");
  }

  const settings = {
    variantName: textFields[0]?.value ?? "",
    displayUrl: textFields[1]?.value ?? "",
    url: textFields[2]?.value ?? "",
    fbPage: textFields[3]?.value ?? "",
    instagramPage: textFields[4]?.value ?? "",
    pixel: textFields[5]?.value ?? "",
    adType: checkAdTypeForPublish(createSpec), // further discussion will going to happen on this
    adName: selectedAd?.name,
  };
  const currentPage =
    extraFields.selectedVariantIndex !== undefined
      ? extraFields.selectedVariantIndex + 1
      : 1;

  const updateSelectVariantIndex = (value: number) => {
    if (extraFields.selectedVariantIndex !== undefined)
      dispatch(
        updateExtraField({
          ...extraFields,
          selectedVariantIndex: extraFields.selectedVariantIndex + value,
        })
      );
  };

  const onSave = (performanceScore?: PerformanceScoreType) => {
    const userPromptText = variantList[extraFields.selectedVariantIndex].payload.userPromptText;
    if (extraFields.selectedVariantIndex !== undefined)
      dispatch(
        updateVariant({
          user: auth.user,
          params: {
            headline: { name: recommendationSelectionState.headline },
            description: { name: recommendationSelectionState.description },
            primaryText: { name: recommendationSelectionState.primary_text },
            adId: selectedAd?.id,
            mediaFileId:
              checkAdType(createSpec) === "link_data"
                ? imageContext?.id
                : videoContext?.id,
            payload: {
              recommendationMetaData,
              settings,
              configureMetaData,
              userPromptText,
              previewExpiry: +new Date(),
              video: recommendationSelectionState.video, // need to incorporate in bundle generation
            },
            performanceScore,
          },
          variantId: variantList[extraFields.selectedVariantIndex].id,
          index: extraFields.selectedVariantIndex,
        })
      );
  };

  const addingVariant = (performanceScore?: PerformanceScoreType) => {
    dispatch(
      addVariant({
        user: auth.user,
        params: {
          headline: { name: recommendationSelectionState.headline },
          description: { name: recommendationSelectionState.description },
          primaryText: { name: recommendationSelectionState.primary_text },
          mediaFileId:
            checkAdType(createSpec) === "link_data"
              ? imageContext?.id
              : videoContext?.id,
          adId: selectedAd?.id,
          adsetId: adSetSelection?.selectedAdSetId,
          elementId: adAccountsSelection?.selectedElementId,
          adAccountId: adAccountsSelection?.selectedAdAccountId,
          payload: {
            adPreviewLink: recommendationSelectionState.adPreviewLink,
            recommendationMetaData,
            settings,
            configureMetaData,
            previewExpiry: +new Date(),
            video: recommendationSelectionState.video, // need to incorporate in bundle generation
          },
          performanceScore,
        },
        finalAction: SET_ADD_VARIANT_RESPONSE,
        adAccountId: adAccountsSelection?.selectedAdAccountId,
      })
    );
  };

  const onDelete = () => {
    if (extraFields.selectedVariantIndex !== undefined)
      dispatch(
        deleteVariantAPICall({
          item: {
            ...variantList[extraFields.selectedVariantIndex],
            index: extraFields.selectedVariantIndex,
          },
          user: auth.user,
        })
      );
    setShowDeletePopUp(false);
  };

  const onCopy = () => {
    if (extraFields.selectedVariantIndex !== undefined) {
      dispatch(
        copyVariant({
          user: auth.user,
          params: {
            headline:
              variantList[extraFields.selectedVariantIndex].CreativeAiData
                .headline,
            description:
              variantList[extraFields.selectedVariantIndex].CreativeAiData
                .description,
            primaryText:
              variantList[extraFields.selectedVariantIndex].CreativeAiData
                .primaryText,
            mediaFileId:
              variantList[extraFields.selectedVariantIndex].CreativeAiData
                .mediaFileId ?? undefined,
            payload: copyVariantNameLogic(
              variantList[extraFields.selectedVariantIndex].payload
            ),
            adId: selectedAd?.id,
            adsetId: adSetSelection?.selectedAdSetId,
            elementId: adAccountsSelection?.selectedElementId,
            adAccountId: adAccountsSelection?.selectedAdAccountId,
            performanceScore:
              variantList[extraFields.selectedVariantIndex]?.performanceScore,
          },
          adAccountId: adAccountsSelection?.selectedAdAccountId,
          finalAction: COPY_VARIANT,
        })
      );
    }
  };

  const showPrompt = () => {
    dispatch(isShowSaveVariantPromptAction(true));
  };

  const onSaveClick = () => {
    if (
      variantList[currentPage - 1].id === null ||
      recommendationSelectionState.primary_text !==
        variantList[extraFields.selectedVariantIndex]?.CreativeAiData
          ?.primaryText ||
      recommendationSelectionState.headline !==
        variantList[extraFields.selectedVariantIndex]?.CreativeAiData
          ?.headline ||
      recommendationSelectionState.description !==
        variantList[extraFields.selectedVariantIndex]?.CreativeAiData
          ?.description
    ) {
      setIsFetchingPFScore(true);

      dispatch(
        fetchPerformanceScore({
          body: {
            brand_name: selectedBrand?.name,
            primary_text: recommendationSelectionState.primary_text,
            headline: recommendationSelectionState.headline,
            description: recommendationSelectionState.description,
          },
          callback: ({ error, performanceScore }) => {
            if (variantList[currentPage - 1].id === null) {
              addingVariant(performanceScore);
            } else {
              onSave(performanceScore);
            }
            setIsFetchingPFScore(false);
          },
        })
      );
      mixPanelUpdateVariant();
    } else {
      const performanceScore =
        variantList[extraFields.selectedVariantIndex].payload
          ?.performance_score;
      onSave(performanceScore);
    }
    dispatch(cancelPerformanceScore());
  };

  return (
    <Fragment>
      <StyledRecommendationFooterDiv>
        <StyledMiddleElement>
          <IconButton
            disabled={extraFields.selectedVariantIndex === 0}
            color="primary"
            aria-label="left_arrow"
            className="mr-3"
            onClick={
              recommendationSelectionState.isChanged ||
              recommendationSelectionState.isConfigureChanged
                ? showPrompt
                : () => {
                    updateSelectVariantIndex(-1);
                    dispatch(setConfigurationState(false));
                    dispatch(
                      updateRecommendationMetadata({
                        data: variantList[currentPage - 2].payload
                          ?.recommendationMetaData,
                        configureMetaData:
                          variantList[currentPage - 2].payload
                            ?.configureMetaData,
                      })
                    );
                    dispatch(
                      updateRecommendationSelectionState({
                        video: variantList[currentPage - 2]?.payload?.video,
                      })
                    );
                  }
            }
          >
            <LeftArrow />
          </IconButton>

          <StyledPaginatedText>
            {currentPage}/{variantList.length}
          </StyledPaginatedText>

          <IconButton
            disabled={
              extraFields.selectedVariantIndex === variantList.length - 1
            }
            color="primary"
            aria-label="right_arrow"
            className="ml-3"
            onClick={
              recommendationSelectionState.isChanged ||
              recommendationSelectionState.isConfigureChanged
                ? showPrompt
                : () => {
                    updateSelectVariantIndex(1);
                    dispatch(setConfigurationState(false));
                    dispatch(
                      updateRecommendationMetadata({
                        data: variantList[currentPage].payload
                          ?.recommendationMetaData,
                        configureMetaData:
                          variantList[currentPage].payload?.configureMetaData,
                      })
                    );
                    dispatch(
                      updateRecommendationSelectionState({
                        video: variantList[currentPage]?.payload?.video,
                      })
                    );
                  }
            }
          >
            <RightArrow />
          </IconButton>
        </StyledMiddleElement>
        <StyledRightElement>
          <IconButton
            color="primary"
            aria-label="copy"
            className="ml-2"
            onClick={
              recommendationSelectionState.isChanged ||
              recommendationSelectionState.isConfigureChanged
                ? showPrompt
                : onCopy
            }
          >
            <Copy />
          </IconButton>
          <IconButton
            color="primary"
            aria-label="delete"
            className="mr-2"
            onClick={
              recommendationSelectionState.isChanged ||
              recommendationSelectionState.isConfigureChanged
                ? showPrompt
                : () => setShowDeletePopUp(true)
            }
          >
            <Delete />
          </IconButton>
          <Button
            buttonVariant="contained"
            disabled={
              !(
                recommendationSelectionState.isChanged ||
                recommendationSelectionState.isConfigureChanged
              ) || isGeneratingPreview
            }
            onClick={
              isUpdatingVariant || isFetchingPFScore ? undefined : onSaveClick
            }
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              fontFamily: "Inter",
            }}
          >
            {isUpdatingVariant || isFetchingPFScore ? (
              <Box width="90px" height="25px">
                <CircularProgress
                  size={25}
                  sx={{
                    color: "white",
                  }}
                  thickness={5}
                />
              </Box>
            ) : (
              `${
                variantList[currentPage - 1].id === null ? "Save " : "Update "
              }Variant #${currentPage}`
            )}
          </Button>
        </StyledRightElement>
      </StyledRecommendationFooterDiv>
      {showDeletePopUp && (
        <ConfirmDialog
          show={showDeletePopUp}
          onClose={() => setShowDeletePopUp(false)}
          title="Are you sure you wanted to delete this variant"
          onComplete={onDelete}
          completeButtonText="Confirm"
          loading={isDeletingVariant}
        />
      )}
    </Fragment>
  );
}
